import React from 'react'

import {
  Container
} from 'components/Grid'

const NotFoundPage = () => (
  <Container>
    <h1>NOT FOUND</h1>
    <p>Page not found.</p>
  </Container>
)

export default NotFoundPage
